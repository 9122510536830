import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppState } from "store/appReducer";

import { getAnalysisAvailableModels, getPreFilteringWordSuggestions } from "services/analysis";
import {
  getAnalysisData,
  getFileById,
  saveFileSettings,
  updateAnalysisFileSettings,
} from "services/files";

import { PreFilteringSuggestedWords } from "ts/analysis";
import { ModelSelectionOptions } from "ts/models";
import { FileSummary } from "ts/file";
import { ErrorType, FileUploadStatus } from "@explorance/mly-types";
import {
  DataSourceErrorDetails,
  handleImportValidationError,
  resetDataSource,
  setIsProcessingFinalStep,
} from "./dataSourceSlice";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";
import { showToastError } from "store/toast/toastSlice";

export const fetchUsedModels = createAsyncThunk<ModelSelectionOptions, string>(
  "usedModels/getAnalysisAvailableModels",
  async (analysisId): Promise<ModelSelectionOptions> => {
    const { data: modelsApiData } = await getAnalysisAvailableModels(parseInt(analysisId));
    if (!modelsApiData) {
      throw new Error("Failed to fetch used models.");
    }
    return modelsApiData;
  }
);

type FetchFileSummaryPayload = FileSummary & {
  suggestedUniqueColumns: string[];
  uploadStatus: FileUploadStatus;
  errorDetails?: DataSourceErrorDetails;
};

export const fetchFileSummary = createAsyncThunk<FetchFileSummaryPayload, number>(
  "dataSource/fetchFileSummary",
  async (fileId): Promise<FetchFileSummaryPayload> => {
    const { data: analysisFileSummary } = await getFileById(fileId);

    if (!analysisFileSummary) {
      throw new Error("Failed to fetch analysis file.");
    }

    return analysisFileSummary;
  }
);

export const fetchAllAnalysisFilesSummary = createAsyncThunk<FileSummary, number>(
  "dataSource/fetchAllAnalysisFilesSummary",
  async (analysisId): Promise<FileSummary> => {
    const { data: analysisFileSummary } = await getAnalysisData(analysisId);

    if (!analysisFileSummary) {
      throw new Error("Failed to fetch analysis file.");
    }
    return analysisFileSummary;
  }
);

export const fetchPreFilteringWordSuggestions = createAsyncThunk<
  PreFilteringSuggestedWords,
  void,
  { state: AppState }
>(
  "prefiltering/fetchWordSuggestions",
  async (_, { getState }): Promise<PreFilteringSuggestedWords> => {
    const state = getState();

    const {
      analysisDetails,
      selectedColumns: initialSelectedColumns,
      commentExclusionCount,
      excludedCommentLimit,
    } = state.settings;

    const selectedColumns = initialSelectedColumns ?? analysisDetails.selectedColumns;

    if (selectedColumns.length === 0) {
      return null;
    }

    const { data: preFilteringSuggestedWords } = await getPreFilteringWordSuggestions({
      analysisId: analysisDetails?.id,
      selectedColumns,
      wordCountThreshold: commentExclusionCount,
      limit: excludedCommentLimit,
      page: 1,
    });

    return preFilteringSuggestedWords;
  }
);

export const handleSaveFileSettings = createAsyncThunk<
  void,
  { modalType: DataSourceModalType; importSuccessCallback?: () => void },
  { state: AppState }
>(
  "dataSource/handleSaveFileSettings",
  async ({ modalType, importSuccessCallback }, { dispatch, getState }) => {
    const state = getState().dataSource;

    dispatch(setIsProcessingFinalStep(true));
    if (modalType === DataSourceModalType.ImportFile) {
      await saveFileSettings(state.importModalFileId, {
        demographics: state.updatedDemographicData,
        selectedUniqueColumn: state.selectedUniqueColumn || null,
        importMode: state.selectedImportType,
      });
      dispatch(setIsProcessingFinalStep(false));
    } else if (modalType === DataSourceModalType.ViewData) {
      try {
        await updateAnalysisFileSettings(state.importModalAnalysisId, state.updatedDemographicData);
        importSuccessCallback && importSuccessCallback();
        dispatch(resetDataSource());
      } catch (e: any) {
        const errorData = e.response.data;

        if (errorData.type === ErrorType.DemographicInvalidDateFormat) {
          dispatch(
            handleImportValidationError({
              type: errorData.type,
              details: {
                ...errorData.details,
                selectedDateFormat: state.updatedDemographicData.find(
                  (d) => d.name === errorData.details.column
                )?.dateFormat,
              },
            })
          );
        } else {
          dispatch(showToastError("toast.defaultError"));
        }
      } finally {
        dispatch(setIsProcessingFinalStep(false));
      }
    }
  }
);
