import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { DotsMenuButton } from "components/_buttons/DotsMenuButton";
import { DropdownMenuItem, DropdownSize } from "ts/dropdown";
import { Tooltip } from "components/Tooltip";
import { DropdownMenu } from "components/DropdownMenu";

import useClickOutside from "hooks/useClickOutside";
import { useResource } from "hooks/useResource";

import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { DEFAULT_DROPDOWN_TRANSITION_TIME } from "components/DropdownMenu/StyledDropdownTransition";
import { Color } from "ts/enums/color";
import { DotsButtonVariant } from "ts/enums/dotsButton";

export type CustomDotButtonStyles = {
  dotColor?: Color;
  backgroundColor?: Color;
  width?: number;
  height?: number;
  margin?: string;
};

type Props = {
  menuContents: DropdownMenuItem[];
  dotsButtonVariant: DotsButtonVariant;
  showDotsButton: boolean;
  menuWidth?: number;
  hasTooltip?: boolean;
  tooltipId?: number;
  shouldCloseMenuOnClick?: boolean;
  size?: DropdownSize;
  altDropdownContent?: ReactElement;
  tooltipDelayHide?: number;
  dotsMenuOpen?: boolean;
  customDotButtonStyles?: Record<string, any>;
  setDotsMenuOpen?: (dotsMenuOpen: boolean) => void;
  onDotsMenuClick?: () => void;
  handleHideUserDetails?: () => void;
};

const DROPDOWN_PADDING = 2;

export const DotsMenu = React.memo(
  ({
    menuContents,
    showDotsButton,
    menuWidth,
    hasTooltip,
    tooltipId,
    shouldCloseMenuOnClick,
    dotsButtonVariant,
    altDropdownContent,
    tooltipDelayHide,
    dotsMenuOpen,
    size,
    setDotsMenuOpen,
    onDotsMenuClick,
    handleHideUserDetails,
  }: Props) => {
    const [popupPositionY, setPopupPositionY] = useState<number>(0);

    const dotsMenuRef = useClickOutside(() => {
      setDotsMenuOpen(false);
    });
    const dropdownRef = useRef<HTMLDivElement>();

    const handleDotsMenuClick = () => {
      onDotsMenuClick && onDotsMenuClick();
      setDotsMenuOpen(!dotsMenuOpen);
    };

    const { getResource } = useResource();

    useEffect(() => {
      if (!dotsMenuOpen && handleHideUserDetails) {
        setTimeout(() => handleHideUserDetails(), DEFAULT_DROPDOWN_TRANSITION_TIME);
      }

      if (dotsMenuOpen) {
        const dotsMenuParams = dotsMenuRef.current.getBoundingClientRect();
        const dropdownParams = dropdownRef.current.getBoundingClientRect();
        const popupContentHeight = dropdownParams.bottom - dropdownParams.top;

        if (
          dotsMenuParams.bottom + popupContentHeight + DROPDOWN_PADDING >=
          window.innerHeight - 1
        ) {
          setPopupPositionY(dotsMenuParams.top - popupContentHeight - DROPDOWN_PADDING);
        } else {
          setPopupPositionY(dotsMenuParams.bottom + DROPDOWN_PADDING);
        }
      }
    }, [dotsMenuOpen]); //eslint-disable-line

    useEffect(() => {
      shouldCloseMenuOnClick && setDotsMenuOpen(false);
    }, [shouldCloseMenuOnClick]); //eslint-disable-line

    return (
      <StyledDotsMenu showDotsButton={showDotsButton || (dotsMenuOpen ?? false)} ref={dotsMenuRef}>
        <DotsMenuButton
          onClick={handleDotsMenuClick}
          isDotsMenuOpen={dotsMenuOpen}
          dotsVariantButton={dotsButtonVariant}
        />

        <DropdownMenu
          items={menuContents}
          altContent={altDropdownContent}
          show={dotsMenuOpen}
          position={{
            top: popupPositionY,
            right: "unset",
            zIndex: ZIndexStackingContext.low,
          }}
          positionType="fixed"
          size={size}
          width={altDropdownContent ? "fit-content" : `${menuWidth}px`}
          handleCloseMenu={shouldCloseMenuOnClick ? () => setDotsMenuOpen(false) : undefined}
          ref={dropdownRef}
        />

        {hasTooltip && !dotsMenuOpen && (
          <Tooltip
            tooltipId={`${tooltipId}-options-tooltip`}
            content={getResource("tooltip.moreOptions")}
            fontSize="0.875em"
            delayHide={tooltipDelayHide}
          />
        )}
      </StyledDotsMenu>
    );
  }
);

const StyledDotsMenu = styled.div<{ showDotsButton: boolean }>`
  visibility: ${({ showDotsButton }) => (showDotsButton ? "visible" : "hidden")};
`;
